import { FunctionComponent, useEffect, useState } from 'react';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { setIsLoading } from '@redux/layoutSlice';
import { setFirstLevelCategoryId } from '@redux/menuTreeSlice';
import MenuTreeManager, { CategoryForTree } from '@business/menu/MenuTreeManager';
import Layout from '@components/layout/Layout';
import MainCategorySelectorOnIndex from '@components/menu/MainCategorySelectorOnIndex';
import MenuTreeComp from '@components/menu/MenuTreeComp';

export interface IndexPageProps
{

}

/**
 * The index page is not only for the actual index page, but also renders the menu tree for the selected main category.
 */
const IndexPage: FunctionComponent<IndexPageProps> = (props) =>
{
    const t = useTranslate();
    const dispatch = useAppActionDispatch();
    const appLanguage = useAppStateSelector(state => state.layout.language);
    const selectedMainCategoryId = useAppStateSelector(state => state.menuTree.selectedMainCategoryId);
    const [menuTree, setMenuTree] = useState<CategoryForTree[]>([]);
    
    // load the menu item tree
    useLoad(async () =>
    {   
        dispatch(setIsLoading(true));
        
        let menuTreeManager = new MenuTreeManager();
        let menuTree = await menuTreeManager.buildTree();
        console.log('IndexPage: menuTree', menuTree);
        setMenuTree(menuTree);

        dispatch(setIsLoading(false));
    });

    // set the first level category id when the main category changes
    useEffect(() =>
    {
        if (selectedMainCategoryId !== undefined)
        {
            let mainCategory = menuTree.find(mainCategory => mainCategory.id == selectedMainCategoryId);
            if (mainCategory && mainCategory.treeChildren.length > 0)
            {
                let firstLevelCategory = mainCategory.treeChildren[0];
                dispatch(setFirstLevelCategoryId(firstLevelCategory.id!));
            }
            else
            {
                dispatch(setFirstLevelCategoryId(undefined));
            }
        }
        else
        {
            dispatch(setFirstLevelCategoryId(undefined));
        }
    }, [menuTree, selectedMainCategoryId]);
    
    /** Render */
    return (
        <Layout>
            <>
            {menuTree.length > 0 &&
                <>
                    {selectedMainCategoryId === undefined && <MainCategorySelectorOnIndex mainCategories={menuTree} />}
                    
                    {selectedMainCategoryId !== undefined && 
                        <MenuTreeComp 
                            menuTree={menuTree}
                            mainCategory={menuTree.find(mainCategory => mainCategory.id == selectedMainCategoryId)!} />
                        }
                </>
            }
            </>
        </Layout>
    );
}

export default IndexPage;