import { capitalizeFirstLetter } from '@xFrame4/common/Functions';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import MenuItemGenerated, { MenuItemGeneratedEntityManager } from './generated/MenuItem.generated';

export class MenuItemEntityManager extends MenuItemGeneratedEntityManager<MenuItem>
{
    constructor()
    {
        super({
            createEntity: () => new MenuItem(),
        });
    }
}

export class MenuItem extends MenuItemGenerated
{
    static _manager: MenuItemEntityManager;
    static get manager(): MenuItemEntityManager
    {
        if (!this._manager) this._manager = new MenuItemEntityManager();
        return this._manager;
    }

    /**
     * Get the category tree as a string. Eg: "Main Category > Sub Category > Sub Sub Category"
     */
    get categoryTreeAsString(): string
    {
        let category = this.category;
        if (!category) return '';

        return category.nameAsTree;
    }

    /**
     * Get translation for the given language
     * 
     * @param language Language code
     */
    getTranslation(language: string): MenuItemTranslation
    {
        let translation: MenuItemTranslation = {
            name: this['name' + capitalizeFirstLetter(language)] ?? '',
            details: this['details' + capitalizeFirstLetter(language)] ?? '',
            allergens: this['allergens' + capitalizeFirstLetter(language)] ?? '',
            ingredients: this['ingredients' + capitalizeFirstLetter(language)] ?? '',
        };

        return translation;
    }
}

export type MenuItemTranslation = {
    name: string;
    details: string;
    allergens: string;
    ingredients: string;
};

export default MenuItem;