import { FunctionComponent } from 'react';
import { useAppStateSelector } from '@redux/hooks';
import { CategoryForTree } from '@business/menu/MenuTreeManager';
import MenuItemsComp from './MenuItemsComp';
import ThirdLevelCategoryComp from './ThirdLevelCategoryComp';

export interface SecondLevelCategoryCompProps
{
    secondLevelCategory: CategoryForTree;
}

/**
 * List the menu items of the second level category.
 */
const SecondLevelCategoryComp: FunctionComponent<SecondLevelCategoryCompProps> = (props) =>
{
    const appLanguage = useAppStateSelector(state => state.layout.language);
    
    /** Render */
    return (
        <div className="second-level-category mb-4">
            <div className="second-level-category-name mb-1 text-menu-gray font-weight-700">
                {props.secondLevelCategory.getTranslation(appLanguage.code).name}
            </div>
            
            {/* List the menu items of the second level category */}
            {props.secondLevelCategory.treeMenuItems.length > 0 && <MenuItemsComp menuItems={props.secondLevelCategory.treeMenuItems} />}

            {/* List the subcategories and their associated menu items (if subcategories exist) */}
            {props.secondLevelCategory.treeChildren && props.secondLevelCategory.treeChildren.length > 0 &&
                <>
                    {props.secondLevelCategory.treeChildren.map(thirdLevelCategory =>
                        <ThirdLevelCategoryComp
                            key={thirdLevelCategory.id}
                            thirdLevelCategory={thirdLevelCategory}
                        />
                    )}
                </>
            }
                
        </div>
    );
}

export default SecondLevelCategoryComp;