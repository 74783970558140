import config, { AppLanguage } from '@config';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface LayoutState
{
    language: AppLanguage;
    isLoading: boolean;
    isLanguageSelectorOpen: boolean;
}

const initialState: LayoutState = {
    language: config.defaultLanguage,
    isLoading: false,
    isLanguageSelectorOpen: false,
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setAppLanguage: (state, action: PayloadAction<AppLanguage>) => {
            state.language = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        toggleLanguageSelector: (state) => {
            state.isLanguageSelectorOpen = !state.isLanguageSelectorOpen;
        },
        setLanguageSelector: (state, action: PayloadAction<boolean>) => {
            state.isLanguageSelectorOpen = action.payload;
        }
    }
});

export const { 
    setAppLanguage, 
    setIsLoading,
    toggleLanguageSelector,
    setLanguageSelector
} = layoutSlice.actions;

export default layoutSlice.reducer;