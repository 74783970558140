import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppStateSelector } from '@redux/hooks';
import { CategoryForTree } from '@business/menu/MenuTreeManager';

export interface MainCategoryNavItemProps
{
    mainCategory: CategoryForTree;
}

const MainCategoryNavItem: FunctionComponent<MainCategoryNavItemProps> = (props) =>
{
    const navigate = useNavigate();
    const selectedMainCategoryId = useAppStateSelector(state => state.menuTree.selectedMainCategoryId);
    const appLanguage = useAppStateSelector(state => state.layout.language);
    
    /** Render */
    return (
        <div 
            className={'main-category-nav-item flex-grow-1 d-flex flex-column align-items-center justify-content-center cursor-pointer ' + (props.mainCategory.id == selectedMainCategoryId ? 'active' : '')}
            onClick={() => navigate(`/${appLanguage.code}/${props.mainCategory.id}`)}
        >
            {props.mainCategory.getTranslation(appLanguage.code).name}
        </div>
    );
}

export default MainCategoryNavItem;