import { FunctionComponent } from 'react';
import { useAppStateSelector } from '@redux/hooks';
import { CategoryForTree } from '@business/menu/MenuTreeManager';
import MenuItemsComp from './MenuItemsComp';

export interface ThirdLevelCategoryCompProps
{
    thirdLevelCategory: CategoryForTree;
}

const ThirdLevelCategoryComp: FunctionComponent<ThirdLevelCategoryCompProps> = (props) =>
{
    const appLanguage = useAppStateSelector(state => state.layout.language);

    /** Render */
    return (
        <div className="third-level-category mb-4">
            <div className="third-level-category-name text-center text-uppercase mb-1">
                {props.thirdLevelCategory.getTranslation(appLanguage.code).name}
            </div>

            {props.thirdLevelCategory.treeMenuItems.length > 0 && <MenuItemsComp menuItems={props.thirdLevelCategory.treeMenuItems} />}
        </div>
    );
}

export default ThirdLevelCategoryComp;