import config, { AppLanguage } from '@config';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface MenuTreeState
{
    selectedMainCategoryId?: number | string;
    selectedFirstLevelCategoryId?: number | string;
}

const initialState: MenuTreeState = {
    selectedMainCategoryId: undefined,
    selectedFirstLevelCategoryId: undefined,
};

/**
 * Slice for the restaurant menu.
 */
export const menuTreeSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setSelectedMainCategoryId: (state, action: PayloadAction<number | string | undefined>) => {
            state.selectedMainCategoryId = action.payload;
        },
        setFirstLevelCategoryId: (state, action: PayloadAction<number | string | undefined>) => {
            state.selectedFirstLevelCategoryId = action.payload;
        }
    }
});

export const { setSelectedMainCategoryId, setFirstLevelCategoryId } = menuTreeSlice.actions;

export default menuTreeSlice.reducer;