import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import Category from '@business/menu/Category';

export enum MenuItemType
{
    FOOD = 'FOOD',
    DRINK = 'DRINK',

}
export enum MenuItemStatus
{
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',

}

export class MenuItemGeneratedEntityManager<B extends MenuItemGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'MenuItem',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'orderNo', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
				{ name: 'nameRo', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'nameHu', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'nameEn', type: BusinessEntityFieldType.VarChar },
				{ name: 'detailsRo', type: BusinessEntityFieldType.Text },
				{ name: 'detailsHu', type: BusinessEntityFieldType.Text },
				{ name: 'detailsEn', type: BusinessEntityFieldType.Text },
				{ name: 'allergensRo', type: BusinessEntityFieldType.Text },
				{ name: 'allergensHu', type: BusinessEntityFieldType.Text },
				{ name: 'allergensEn', type: BusinessEntityFieldType.Text },
				{ name: 'ingredientsRo', type: BusinessEntityFieldType.Text },
				{ name: 'ingredientsHu', type: BusinessEntityFieldType.Text },
				{ name: 'ingredientsEn', type: BusinessEntityFieldType.Text },
				{ name: 'weightsVolumes', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'price', type: BusinessEntityFieldType.Decimal, isRequiredInput: true },
				{ name: 'category', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'categoryId', inputProperty: 'category.id', relatedManager: Category.manager },
				{ name: 'type', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'status', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'energyKcal', type: BusinessEntityFieldType.Decimal },
				{ name: 'energyKj', type: BusinessEntityFieldType.Decimal },
				{ name: 'fatsG', type: BusinessEntityFieldType.Decimal },
				{ name: 'saturatedFatsG', type: BusinessEntityFieldType.Decimal },
				{ name: 'carbohydratesG', type: BusinessEntityFieldType.Decimal },
				{ name: 'sugarsG', type: BusinessEntityFieldType.Decimal },
				{ name: 'proteinsG', type: BusinessEntityFieldType.Decimal },
				{ name: 'saltG', type: BusinessEntityFieldType.Decimal },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'menuItems',
        });
    }

}
export class MenuItemGenerated extends BusinessEntity
{
	orderNo: number = 0;
	nameRo!: string;
	nameHu!: string;
	nameEn: string = '';
	detailsRo: string = '';
	detailsHu: string = '';
	detailsEn: string = '';
	allergensRo: string = '';
	allergensHu: string = '';
	allergensEn: string = '';
	ingredientsRo: string = '';
	ingredientsHu: string = '';
	ingredientsEn: string = '';
	weightsVolumes!: string;
	price!: number;
	category!: Category;
	type!: MenuItemType;
	status: MenuItemStatus = MenuItemStatus.ACTIVE;
	energyKcal: number | null = null;
	energyKj: number | null = null;
	fatsG: number | null = null;
	saturatedFatsG: number | null = null;
	carbohydratesG: number | null = null;
	sugarsG: number | null = null;
	proteinsG: number | null = null;
	saltG: number | null = null;

}

export default MenuItemGenerated;