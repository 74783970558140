import config, { AppLanguage } from '@config';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { setAppLanguage, setLanguageSelector, toggleLanguageSelector } from '@redux/layoutSlice';
import { FunctionComponent, useEffect } from 'react';

interface LanguageSelectorProps
{

}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = (props) =>
{
    const dispatch = useAppActionDispatch();
    const appLanguage = useAppStateSelector(state => state.layout.language);
    const isLanguageSelectorOpen = useAppStateSelector(state => state.layout.isLanguageSelectorOpen);

    /**
     * A language has been selected
     */
    const onItemClick = (language: AppLanguage) =>
    {
        dispatch(setLanguageSelector(false));
        dispatch(setAppLanguage(language));
    }

    /**
     * Handle outside click (close language selector)
     */
    useEffect(() =>
    {
        const handleClick = (e: MouseEvent) =>
        {
            if (!(e.target as any).closest('.language-selector') && !(e.target as any).closest('.language-selector-button'))
            {
                dispatch(setLanguageSelector(false));
            }
            else
            {
                console.log('clicked inside language selector');
            }
        }
        document.addEventListener('click', handleClick);

        return () =>
        {
            document.removeEventListener('click', handleClick);
        }
    });

    /** Render */
    return (
        <>
            <div
                className="language-selector-button cursor-pointer"
                onClick={() => dispatch(toggleLanguageSelector())}
            >
                {appLanguage.name}
                <i className="fas fa-chevron-up ms-2" style={{ display: isLanguageSelectorOpen ? 'inline' : 'none' }}></i>
                <i className="fas fa-chevron-down ms-2" style={{ display: isLanguageSelectorOpen ? 'none' : 'inline' }}></i>
            </div>

            <div className="language-selector px-3 pt-3 pb-3 mt-5" style={{ display: isLanguageSelectorOpen ? 'block' : 'none' }}>
                <div className="row">
                    {
                        config.languages.map((language) =>
                            <div
                                key={language.code}
                                className={'language-selector-item cursor-pointer mb-3 ' + (language.code === appLanguage.code ? 'fw-bold' : '')}
                                onClick={() => onItemClick(language)}
                            >
                                {language.name}
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default LanguageSelector;