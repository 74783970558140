import MenuItem from '@business/menu/MenuItem';
import { FunctionComponent } from 'react';
import MenuItemComp from './MenuItemComp';

export interface MenuItemsCompProps
{
    menuItems: MenuItem[];
}

/**
 * List the menu items.
 */
const MenuItemsComp: FunctionComponent<MenuItemsCompProps> = (props) =>
{
    /** Render */
    return (
        <div className="menu-items">
            {
                props.menuItems.map((menuItem) =>
                    <MenuItemComp
                        key={menuItem.id}
                        menuItem={menuItem}
                    />
                )
            }
        </div>
    );
}

export default MenuItemsComp;