import { FunctionComponent } from 'react';
import { useAppStateSelector } from '@redux/hooks';
import { useTranslate } from '@xFrame4/components/Hooks';
import { numberFormat } from '@xFrame4/common/Functions';
import MenuItem from '@business/menu/MenuItem';

export interface MenuItemFoodCompProps
{
    menuItem: MenuItem;
}

const MenuItemFoodComp: FunctionComponent<MenuItemFoodCompProps> = (props) =>
{
    const t = useTranslate();
    const appLanguage = useAppStateSelector(state => state.layout.language);

    /** Render */
    return (
        <div className="menu-item menu-item-food text-center mb-5">
            <div className="mb-1">
                <div className="menu-item-food-name font-size-2 font-weight-700">
                    {props.menuItem.getTranslation(appLanguage.code).name}
                </div>
                <div className="menu-item-food-details">
                    {props.menuItem.getTranslation(appLanguage.code).details}
                </div>
                {props.menuItem.weightsVolumes != '' &&
                    <div className="menu-item-food-weight-volumes">
                        {props.menuItem.weightsVolumes}
                    </div>
                }
            </div>

            <div className="menu-item-food-ingredients-and-allergens small text-menu-gray fst-italic mb-1">
                {props.menuItem.getTranslation(appLanguage.code).ingredients != '' &&
                    <div className="menu-item-food-ingredients">
                        {props.menuItem.getTranslation(appLanguage.code).ingredients}
                    </div>
                }
                {props.menuItem.getTranslation(appLanguage.code).allergens != '' &&
                    <div className="menu-item-food-allergens">
                        {t('ALLERGENS') + ': ' + props.menuItem.getTranslation(appLanguage.code).allergens}
                    </div>
                }
            </div>

            <div className="menu-item-food-nutritional-values small text-menu-gray fst-italic">
                <div className="nutritional-values-title">
                    {t('NUTRITIONAL_VALUES')}/100gr:
                </div>
                <div className="nutritional-values-line-1">
                    {t('ENERGY_VALUE')} {props.menuItem.energyKcal} {t('KCAL')} / {props.menuItem.energyKj} {t('KJ')},
                </div>
                <div className="nutritional-values-line-2">
                    {t('FATS')} {props.menuItem.fatsG}g, {t('SATURATED_FATS')} {props.menuItem.saturatedFatsG}g, {t('GLUCIDS')} {props.menuItem.carbohydratesG}g,
                </div>
                <div className="nutritional-values-line-3">
                    {t('SUGARS')} {props.menuItem.sugarsG}g, {t('PROTEINS')} {props.menuItem.proteinsG}g, {t('SALT')} {props.menuItem.saltG}g
                </div>
            </div>

            <div className="menu-item-food-price font-size-2 font-weight-700">
                {numberFormat(props.menuItem.price, 2, ',', '.')} {t('RON')}
            </div>
        </div>
    );
}

export default MenuItemFoodComp;