import config from '@config';
import React from 'react';
import ReactDom from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { store } from '@redux/store';
import { Provider } from 'react-redux';
import IndexPage from '@components/pages/IndexPage';

run();

function run()
{
    const rootElement = document.getElementById('root');
    
    if (rootElement != null)
    {
        const root = ReactDom.createRoot(rootElement);
        root.render(
            <Provider store={store}>
                <BrowserRouter basename={config.reactRouterBaseName}>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route index element={<IndexPage />} />

                            <Route path=":language">
                                <Route index element={<IndexPage />} />

                                <Route path=":mainCategoryId" element={<IndexPage />} />
                            </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Provider>,
        );
    }
}