import { configureStore } from '@reduxjs/toolkit';
import userReducer from '@xFrame4/redux/userSlice';
import layoutReducer from './layoutSlice';
import trackerReducer from '@xFrame4/redux/trackerSlice';
import entityManagementReducer from '@xFrame4/redux/entityManagementSlice';
import menuTreeReducer from './menuTreeSlice';

// https://react-redux.js.org/tutorials/quick-start
// https://react-redux.js.org/using-react-redux/usage-with-typescript
export const store = configureStore({
    reducer: {
        user: userReducer,
        layout: layoutReducer,
        tracker: trackerReducer,
        entityManagement: entityManagementReducer,
        menuTree: menuTreeReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;