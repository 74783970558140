import { FunctionComponent } from 'react';
import { useAppStateSelector } from '@redux/hooks';
import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';

export interface layoutProps
{
    children: JSX.Element | JSX.Element[];
}

const layout: FunctionComponent<layoutProps> = (props) =>
{
    const isLoading = useAppStateSelector(state => state.layout.isLoading);
    const isIndex = useAppStateSelector(state => state.menuTree.selectedMainCategoryId) === undefined;

    /** Render */
    return (
        <>
            {isLoading && <Loading />}
            
            <div 
                className={'layout ' + (isIndex ? 'index' : '')}
            >
                <Header />

                {props.children}
                
                <Footer />
            </div>
        </>
    );
}

export default layout;