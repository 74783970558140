import { FunctionComponent } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';

export interface LoadingProps
{

}

const Loading: FunctionComponent<LoadingProps> = (props) =>
{
    const t = useTranslate();
    
    /** Render */
    return (
        <div className="loading">
            <Spinner variant="menu-gold" animation="border" role="status">
                <span className="sr-only">{t('LOADING')}</span>
            </Spinner>
        </div>
    );
}

export default Loading;