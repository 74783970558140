import { FunctionComponent, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import config from '@config';
import { useTranslate } from '@xFrame4/components/Hooks';
import { useAppActionDispatch } from '@redux/hooks';
import { setAppLanguage } from '@redux/layoutSlice';
import { setFirstLevelCategoryId, setSelectedMainCategoryId } from '@redux/menuTreeSlice';

interface AppProps
{

}

const App: FunctionComponent<AppProps> = () =>
{
    const t = useTranslate();
    const params = useParams();
    const dispatch = useAppActionDispatch();

    // set document title
    useEffect(() =>
    {
        document.title = t('APP_TITLE');
    }, []);

    // set app language from the url (changes the default language in the redux store)
    useEffect(() =>
    {
        // get the language from the url
        let paramsLanguageCode = params.language;
        let languageFromParams = config.languages.find(language => language.code === paramsLanguageCode)

        // change the default language
        if (languageFromParams !== undefined)
        {
            dispatch(setAppLanguage(languageFromParams));
        }
    }, [params.language]);

    // set the selected main category type from the url
    useEffect(() =>
    {
        // get the main category type from the url
        let mainCategoryId = params.mainCategoryId;

        // set the selected main category type (can be undefined if the main category is not selected)
        dispatch(setSelectedMainCategoryId(mainCategoryId));
    }, [params.mainCategoryId]);

    // set the selected first level category ID from the url
    useEffect(() =>
    {
        // get the first level category ID from the url
        let firstLevelCategoryId = params.firstLevelCategoryId;

        // set the selected first level category ID (can be undefined if the first level category is not selected)
        if (firstLevelCategoryId !== undefined)
        {
            dispatch(setFirstLevelCategoryId(firstLevelCategoryId));
        }
    }, [params.firstLevelCategoryId]);

    /** Render */
    return (
        <>
            <Outlet />
        </>
    );
}

export default App;