import { FunctionComponent } from 'react';
import { CategoryForTree } from '@business/menu/MenuTreeManager';
import MainCategoryNav from './MainCategoryNav';
import FirstLevelCategoryNav from './FirstLevelCategoryNav';
import MainCategoryComp from './MainCategoryComp';

export interface MenuTreeCompProps
{
    /** The whole menu tree */
    menuTree: CategoryForTree[];
    /** A root category from the tree (eg: FOOD, DRINK, etc.) */
    mainCategory: CategoryForTree;
}

const MenuTreeComp: FunctionComponent<MenuTreeCompProps> = (props) =>
{
    /** Render */
    return (
        <div className="menu-tree">
            <div className="menu-tree-fixed-header">
                <div className="container">
                    <MainCategoryNav mainCategories={props.menuTree} />
                    <FirstLevelCategoryNav firstLevelCategories={props.mainCategory.treeChildren} />
                </div>
            </div>

            <div className="container">
                <MainCategoryComp mainCategory={props.mainCategory} />
            </div>
        </div>
    );
}

export default MenuTreeComp;