import { FunctionComponent } from 'react';
import { CategoryForTree } from '@business/menu/MenuTreeManager';
import FirstLevelCategoryNavItem from './FirstLevelCategoryNavItem';
import { useAppStateSelector } from '@redux/hooks';

export interface FirstLevelCategoryNavProps
{
    firstLevelCategories: CategoryForTree[];
}

/**
 * Horizontal navigation bar for first level categories. Horizontal scroll is enabled.
 */
const FirstLevelCategoryNav: FunctionComponent<FirstLevelCategoryNavProps> = (props) =>
{
    /** Render */
    return (
        <div className="first-level-category-nav pb-3">
            <div className="first-level-category-nav-items d-flex py-1">
                {props.firstLevelCategories.map(category => (
                    <FirstLevelCategoryNavItem
                        key={category.id}
                        firstLevelCategory={category}
                    />
                ))}
            </div>
        </div>
    );
}

export default FirstLevelCategoryNav;