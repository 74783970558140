import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '@config';
import SmoothImage from '@xFrame4/components/common/SmoothImage';
import { useAppStateSelector } from '@redux/hooks';
import LanguageSelector from './LanguageSelector';

export interface HeaderProps
{

}

const Header: FunctionComponent<HeaderProps> = (props) =>
{
    const navigate = useNavigate();
    const appLanguage = useAppStateSelector(state => state.layout.language);
    const isIndex = useAppStateSelector(state => state.menuTree.selectedMainCategoryId) === undefined;
    const [logoInfo, setLogoInfo] = useState({ src: '', width: 0, height: 0 });

    useEffect(() =>
    {
        setLogoInfo({
            src: config.defaultPublicDirBaseUrl + (isIndex ? 'img/logos/index-logo.webp' : 'img/logos/logo.webp'),
            width: isIndex ? 700 : 200,
            height: isIndex ? 578 : 200
        });
    }, [isIndex]);
    
    /** Render */
    return (
        <div className="header py-2">
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <div className="logo">
                            <SmoothImage 
                                src={logoInfo.src}
                                width={logoInfo.width}
                                height={logoInfo.height}
                                className="cursor-pointer"
                                onClick={() => navigate('/' + appLanguage.code)}
                            />
                        </div>
                    </div>
                    <div className="col-6 d-flex justify-content-end align-items-center">
                        <LanguageSelector />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;