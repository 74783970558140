import { FunctionComponent } from 'react';
import { CategoryForTree } from '@business/menu/MenuTreeManager';
import FirstLevelCategoryComp from './FirstLevelCategoryComp';

export interface MainCategoryCompProps
{
    mainCategory: CategoryForTree;
}

/**
 * List the subcategories and their associated menu items of the main category
 */
const MainCategoryComp: FunctionComponent<MainCategoryCompProps> = (props) =>
{
    /** Render */
    return (
        <div className="main-category">
            <div className="main-category-padding-top pt-4"></div>

            {props.mainCategory.treeChildren.map((category) => (
                <FirstLevelCategoryComp
                    key={category.id}
                    firstLevelCategory={category}
                />
            ))}
        </div>
    );
}

export default MainCategoryComp;