import { FunctionComponent } from 'react';
import { useTranslate } from '@xFrame4/components/Hooks';
import { numberFormat } from '@xFrame4/common/Functions';
import { useAppStateSelector } from '@redux/hooks';
import MenuItem from '@business/menu/MenuItem';

export interface MenuItemDrinkCompProps
{
    menuItem: MenuItem;
}

const MenuItemDrinkComp: FunctionComponent<MenuItemDrinkCompProps> = (props) =>
{
    const t = useTranslate();
    const appLanguage = useAppStateSelector(state => state.layout.language);
    
    /** Render */
    return (
        <div className="menu-item menu-item-drink font-size-r90 d-flex mb-2">
            <div className="menu-item-drink-name w-60">
                {props.menuItem.getTranslation(appLanguage.code).name}
            </div>
            <div className="menu-item-drink-volume w-20 text-end">
                {props.menuItem.weightsVolumes}
            </div>
            <div className="menu-item-drink-price w-30 text-end">
                {numberFormat(props.menuItem.price, 2, ',', '.')} {t('RON')}
            </div>
        </div>
    );
}

export default MenuItemDrinkComp;