import { FunctionComponent, useEffect, useRef, useState } from 'react';
import SmoothImage from '@xFrame4/components/common/SmoothImage';
import { freshImageUrl, scrollToElementWithOffset } from '@xFrame4/common/Functions';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { setFirstLevelCategoryId } from '@redux/menuTreeSlice';
import { CategoryForTree } from '@business/menu/MenuTreeManager';
import MenuItemsComp from './MenuItemsComp';
import SecondLevelCategoryComp from './SecondLevelCategoryComp';

export interface FirstLevelCategoryCompProps
{
    firstLevelCategory: CategoryForTree;
}

/**
 * List the menu items of the first level category.
 * List the subcategories and their associated menu items (if subcategories exist)
 */
const FirstLevelCategoryComp: FunctionComponent<FirstLevelCategoryCompProps> = (props) =>
{
    const dispatch = useAppActionDispatch();
    const appLanguage = useAppStateSelector(state => state.layout.language);
    const ref = useRef<HTMLDivElement>(null);
    const selectedFirstLevelCategoryId = useAppStateSelector(state => state.menuTree.selectedFirstLevelCategoryId);

    /**
     * Calculate the offset when scrolling to the element or when watching the scroll event.
     * The offset is the sum of the header and the menu tree fixed header heights.
     */
    const calculateOffset = () =>
    {
        let offset = 0;
        let headerElement = document.querySelector('.header');
        if (headerElement)
        {
            offset += headerElement.clientHeight;
        }
        let menuTreeFixedHeaderElement = document.querySelector('.menu-tree-fixed-header');
        if (menuTreeFixedHeaderElement)
        {
            offset += menuTreeFixedHeaderElement.clientHeight;
        }
        // let mainCategoryPaddingTopElement = document.querySelector('.main-category-padding-top');
        // if (mainCategoryPaddingTopElement)
        // {
        //     offset += mainCategoryPaddingTopElement.clientHeight;
        // }

        return offset;
    }

    /**
     * Scroll the selected first level category into view.
     */
    useEffect(() =>
    {
        if (ref.current == null) return;
        
        if (selectedFirstLevelCategoryId == props.firstLevelCategory.id)
        {
            console.log('scroll element into view');
            // calculate the offset
            let offset = calculateOffset();
            
            // scroll to the element
            scrollToElementWithOffset(ref.current, offset);
        }
    }, [selectedFirstLevelCategoryId]);

    /**
     * Watch the scroll event to set the selected first level category (when the first level category is in view).
     */
    useEffect(() =>
    {
        const scrollHandler = () =>
        {
            // console.log('scrollHandler');
            // const rect = ref.current?.getBoundingClientRect();
            // let offset = calculateOffset();
            // if (props.firstLevelCategory.id == 6 || props.firstLevelCategory.id == 7)
            // {
            //     console.log('props.firstLevelCategory.id', props.firstLevelCategory.id);
            //     console.log('offset', offset);
            //     console.log('rect', rect);
            //     console.log('window.innerHeight', window.innerHeight);
            // }

            // if (rect && rect.top >= offset && rect.top <= window.innerHeight - offset)
            // {
            //     dispatch(setFirstLevelCategoryId(props.firstLevelCategory.id!));
            // }
        };

        window.addEventListener('scroll', scrollHandler);

        return () =>
        {
            window.removeEventListener('scroll', scrollHandler);
        };
    });

    /** Render */
    return (
        <div 
            className="first-level-category mb-5"
            ref={ref}
        >
            <div className="first-level-category-name text-menu-gray text-center font-size-3 font-weight-500 mb-1">
                {props.firstLevelCategory.getTranslation(appLanguage.code).name}
            </div>

            <div className="first-level-category-image d-flex justify-content-center mb-4">
                <SmoothImage 
                    src={freshImageUrl(props.firstLevelCategory.logoUrl)}
                    width={200}
                    height={200}
                />
            </div>
            
            {/* List the menu items of the first level category if they exist */}
            {props.firstLevelCategory.treeMenuItems.length > 0 && <MenuItemsComp menuItems={props.firstLevelCategory.treeMenuItems} />}
            
            {/* List the subcategories and their associated menu items (if subcategories exist) */}
            {props.firstLevelCategory.treeChildren && props.firstLevelCategory.treeChildren.length > 0 &&
                <>
                    {props.firstLevelCategory.treeChildren.map(secondLevelCategory =>
                        <SecondLevelCategoryComp
                            key={secondLevelCategory.id}
                            secondLevelCategory={secondLevelCategory}
                        />
                    )}
                </>
            }
        </div>
    );
}

export default FirstLevelCategoryComp;