import { CategoryForTree } from '@business/menu/MenuTreeManager';
import { FunctionComponent } from 'react';
import MainCategoryNavItem from './MainCategoryNavItem';

export interface MainCategoryNavProps
{
    mainCategories: CategoryForTree[];
}

/**
 * A horizontal navigation bar for the main categories.
 */
const MainCategoryNav: FunctionComponent<MainCategoryNavProps> = (props) =>
{
    /** Render */
    return (
        <div className="main-category-nav d-flex mb-1">
            {props.mainCategories.map(mainCategory => (
                <MainCategoryNavItem 
                    key={mainCategory.id}
                    mainCategory={mainCategory}
                />
            ))}
        </div>
    );
}

export default MainCategoryNav;