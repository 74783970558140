import { gql } from '@apollo/client';
import client from '@graphql/apollo-client';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import Category, { CategoryEntityManager } from './Category';
import MenuItem, { MenuItemEntityManager } from './MenuItem';

export class CategoryForTreeManager extends CategoryEntityManager
{
    constructor()
    {
        super({
            createEntity: () => new CategoryForTree(),
            fields: [
                { name: 'treeChildren', type: BusinessEntityFieldType.BusinessEntityArray, relatedManager: 'self' },
                { name: 'treeMenuItems', type: BusinessEntityFieldType.BusinessEntityArray, relatedManager: MenuItem.manager },
            ]
        });
    }
}

export class CategoryForTree extends Category
{
    treeChildren: CategoryForTree[] = [];
    treeMenuItems: MenuItem[] = [];

    static get manager(): CategoryForTreeManager
    {
        this._manager = new CategoryForTreeManager();
        return this._manager;
    }
}

/**|
 * A class to handle the restaurant menu tree.
 */
export class MenuTreeManager
{
    async buildTree()
    {
        // the fragment for the category details
        let categoryFieldsFragment = Category.manager.buildEntityFieldsFragment();
        let extraCategoryFields = `
            treeMenuItems {
                ...MenuItemFieldsFragment
            }
        `;
        categoryFieldsFragment = categoryFieldsFragment.replace('}', ` ${extraCategoryFields}}`);

        // the fragment for the menu item details
        let menuItemFieldsFragment = MenuItem.manager.buildEntityFieldsFragment(['category']);
        
        // the query to build the tree
        let query = `
            query BuildTree {
                buildTree {
                    ...CategoryFieldsFragment
                    treeChildren {
                        ...CategoryFieldsFragment
                        treeChildren {
                            ...CategoryFieldsFragment
                            treeChildren {
                                ...CategoryFieldsFragment
                            }
                        }
                    }
                }
            }

            ${categoryFieldsFragment}
            ${menuItemFieldsFragment}
        `;

        // execute the query
        let result = await client.query({
            query: gql(query)
        });

        // specific managers for the entities
        let categoryManager = new CategoryForTreeManager();
        let menuItemManager = new MenuItemEntityManager();
        menuItemManager.fields = menuItemManager.fields.filter(f => f.name !== 'category'); // remove the category field from the menu item manager

        let categories: CategoryForTree[] = [];
        for (let categoryData of result.data.buildTree)
        {
            let category = categoryManager.createFromGraphQL(categoryData) as CategoryForTree;
            categories.push(category);
        }

        return categories;
    }

    /**
     * Get the main categories at the root level.
     */
    getMainCategories(tree: CategoryForTree[]): CategoryForTree[]
    {
        return tree.filter(c => c.parent === null);
    }

    /**
     * Get a main category by its id.
     */
    getMainCategoryById(mainCategoryId: string|number, tree: CategoryForTree[]): CategoryForTree | undefined
    {
        return this.getMainCategories(tree).find(c => c.id === mainCategoryId);
    }

    /**
     * Get the first level categories for the given main category.
     */
    getFirstLevelCategoriesForMainCategory(mainCategoryId: string|number, tree: CategoryForTree[]): CategoryForTree[]
    {
        let mainCategories = this.getMainCategories(tree);
        let mainCategory = mainCategories.find(c => c.id === mainCategoryId);
        if (!mainCategory) return [];

        return mainCategory.treeChildren;
    }
}

export default MenuTreeManager;