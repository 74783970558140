import { FunctionComponent } from 'react';
import MenuItem from '@business/menu/MenuItem';
import { MenuItemType } from '@business/menu/generated/MenuItem.generated';
import MenuItemFoodComp from './MenuItemFoodComp';
import MenuItemDrinkComp from './MenuItemDrinkComp';

export interface MenuItemCompProps
{
    menuItem: MenuItem;
}

const MenuItemComp: FunctionComponent<MenuItemCompProps> = (props) =>
{
    /** Render */
    return (
        <>
            {props.menuItem.type === MenuItemType.FOOD && <MenuItemFoodComp menuItem={props.menuItem} />}
            {props.menuItem.type === MenuItemType.DRINK && <MenuItemDrinkComp menuItem={props.menuItem} />}
        </>
    );
}

export default MenuItemComp;