import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';


export enum CategoryMainCategoryType
{
    FOOD = 'FOOD',
    DRINK = 'DRINK',

}
export enum CategoryStatus
{
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',

}

export class CategoryGeneratedEntityManager<B extends CategoryGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'Category',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'orderNo', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
				{ name: 'nameHu', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'nameRo', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'nameEn', type: BusinessEntityFieldType.VarChar },
				{ name: 'parent', type: BusinessEntityFieldType.ForeignKey, inputName: 'parentId', inputProperty: 'parent.id', relatedManager: 'self' },
				{ name: 'mainCategoryType', type: BusinessEntityFieldType.VarChar },
				{ name: 'status', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'categories',
        });
    }

}
export class CategoryGenerated extends BusinessEntity
{
	orderNo: number = 0;
	nameHu!: string;
	nameRo!: string;
	nameEn: string = '';
	parent: CategoryGenerated | null = null;
	mainCategoryType: CategoryMainCategoryType | null = null;
	status: CategoryStatus = CategoryStatus.ACTIVE;

}

export default CategoryGenerated;