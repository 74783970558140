import { FunctionComponent } from 'react';
import { CategoryForTree } from '@business/menu/MenuTreeManager';
import MainCategoryOnIndex from './MainCategoryOnIndex';
import SmoothImage from '@xFrame4/components/common/SmoothImage';
import config from '@config';

export interface MainCategorySelectorOnIndexProps
{
    mainCategories: CategoryForTree[];
}

/**
 * Select the main category on the index page.
 */
const MainCategorySelectorOnIndex: FunctionComponent<MainCategorySelectorOnIndexProps> = (props) =>
{
    /** Render */
    return (
        <div className="main-category-selector-on-index">
            <div className="row g-0 justify-content-center">
                <div className="col-md-10 col-lg-4">
                    <div className="main-category-selector-on-index-logo d-flex flex-column align-items-center mb-5 pt-5">
                        <div className="w-40">
                            <SmoothImage
                                src={config.defaultPublicDirBaseUrl + 'img/logos/index-logo.webp'}
                                width={700}
                                height={578}
                                className="img-fluid mb-5"
                            />
                        </div>

                        <div className="w-70">
                            <SmoothImage
                                src={config.defaultPublicDirBaseUrl + 'img/index-logo-separator.webp'}
                                width={600}
                                height={28}
                                className="img-fluid"
                            />
                        </div>
                    </div>

                    <div className="main-categories-on-index d-flex align-items-center justify-content-center">
                        {
                            props.mainCategories.map((mainCategory, index) =>
                                <MainCategoryOnIndex key={index} mainCategory={mainCategory} />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainCategorySelectorOnIndex;