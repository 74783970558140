import { ApolloQueryResult, gql } from '@apollo/client';
import client from '@xFrame4/business/GraphQlClient';
import { uniqueId } from '@xFrame4/common/Functions';
import xObject from '@xFrame4/common/xObject';
import { BusinessEntityFieldType } from './Constants';
import ManyToManyCollection from './ManyToManyCollection';

export class BusinessEntity extends xObject
{
    [index: string]: any; //https://www.typescriptlang.org/docs/handbook/2/objects.html#index-signatures
    /** The primary key. */
    id: string | number | null = null;
    /** A unique ID to identify the entity even if it has not been saved. */
    uniqueId: string = uniqueId();
    /** The edge { cursor } value from the GraphQL query (for pagination). */
    cursor?: string;
    /** The raw GraphQL data loaded from the API. */
    graphQlObject: any;
    /** The EntityManager object for this entity. */
    static manager: EntityManager<BusinessEntity>;

    /**
     * Fill the entity's fields from a GraphQL object.
     * 
     * @param graphQlObject An object from the GraphQL API response.
     */
    copyFromGraphQL(graphQlObject: any)
    {
        const cls = this['constructor'] as typeof BusinessEntity; // https://stackoverflow.com/questions/20851358/access-child-class-static-members-from-base-class

        cls.manager.copyFromGraphQL(this, graphQlObject);
    }

    /**
     * Saves the entity as a new one or updates an existing via GraphQL.
     * 
     * @returns True if succeeded, false if not.
     */
    async save()
    {
        const cls = this['constructor'] as typeof BusinessEntity;

        return await cls.manager.saveEntity(this);
    }

    /**
     * Deletes the entity via GraphQL.
     * 
     * @returns True if succeeded, false if not.
     */
    async delete()
    {
        const cls = this['constructor'] as typeof BusinessEntity;

        return await cls.manager.deleteEntity(this);
    }

    /**
     * Create a copy of the entity.
     */
    copy()
    {
        const cls = this['constructor'] as typeof BusinessEntity;
        
        // create a new instance of the same class
        let copy = new cls();

        // copy all properties
        copy = Object.assign(copy, this);

        // if a property is a ManyToManyCollection object: update the entity reference to the new entity
        Object.keys(copy).forEach(key => {
            if (copy[key] instanceof ManyToManyCollection)
            {
                copy[key].entity = copy;   
            }
        });

        return copy;
    }

    /** 
     * Convert the entity to a serializable JSON object. 
     * Sometime this will not work (for complex objects). In this case use a light variant of the BusinessEntity.
     */
    toJson()
    {
        // create a copy of the object
        let object: any = this.copy();

        // remove unserializable properties
        delete object.eventHandlers;

        // remove many-to-many collections
        Object.keys(object).forEach(key => {
            if (object[key] instanceof ManyToManyCollection)
            {
                delete object[key];
            }
        });

        // handle BusinessEntity objects
        Object.keys(object).forEach(key => {
            if (object[key] instanceof BusinessEntity)
            {
                object[key] = object[key].toJson();
            }
            else if (Array.isArray(object[key]))
            {
                object[key] = object[key].map((item: any) => {
                    if (item instanceof BusinessEntity)
                    {
                        return item.toJson();
                    }
                    else
                    {
                        return item;
                    }
                });
            }
        });

        return JSON.parse(JSON.stringify(object));
    }

    /**
     * Convert an array of entities to a serializable JSON object. 
     * Useful with Next.js getStaticProps serialize/deserialize.
     * 
     * @param entities An array of BusinessEntity objects.
     */
    static arrayToJson(entities: BusinessEntity[])
    {
        return entities.map(entity => entity.toJson());
    }

    /**
     * Create an entity from a GraphQL object. Use this if the method on the manager is not convenient.
     * 
     * @param graphQlObject An object from the GraphQL API response.
     */
    static createFromGraphQL(graphQlObject: any)
    {
        if (graphQlObject === null) return null;
        
        let entity = new this();
        entity.copyFromGraphQL(graphQlObject);

        return entity;
    }

    /**
     * Create a BusinessEntity from a JSON object (or from any object as a matter of fact). 
     * The structure needs to be the same as of a BusinessEntity. 
     * For now this is an alias for createFromGraphQL.
     * Useful with Next.js getStaticProps serialize/deserialize.
     * 
     * @param jsonObject A BusinessEntity JSON object that was created with toJson().
     */
    static createFromJson(jsonObject: any)
    {
        return this.createFromGraphQL(jsonObject);
    }

    /**
     * Create a list of BusinessEntity objects from a list of JSON objects.
     * Uses createFromJson.
     * Useful with Next.js getStaticProps serialize/deserialize.
     * 
     * @param jsonObjects 
     */
    static createArrayFromJson(jsonObjects: any[])
    {
        return jsonObjects.map(jsonObject => this.createFromGraphQL(jsonObject));
    }

    /**
     * Make a copy of an array of entities.
     * 
     * @param entities 
     */
    static copyArray(entities: BusinessEntity[])
    {
        return entities.map(entity => entity.copy());
    }
}

/**
 * Represents an entity field. The field data connects the BusinessEntity with GraphQL and D
 */
export class BusinessEntityField
{
    /** The field name. */
    name: string;
    /** The field type. */
    type: BusinessEntityFieldType;
    /** The class property associated with the input field. By default this is the field name. Can refer to a property of a property, eg: page.id or page.author.id. */
    inputProperty: string;
    /** Is the field present in the GraphQL field details query (FieldsFragment). */
    isInFieldDetails: boolean;
    /** The class property associated with the input field. By default this is the field name. */
    mapToProperty?: string;
    /** Is the field a GraphQL input field. */
    isInput: boolean;
    /** Use this if the GraphQL input field name is different from 'name'. Eg: name: user -> inputName: userId, name: imageUrl -> inputName: image */
    inputName?: string;
    /** Is this field a required input? */
    isRequiredInput: boolean;
    /** Is the input an array of entities? */
    isArrayInput?: boolean;
    /** The EntityManager object for this field (the field represents an entity). Use an EntityManager or the string 'self' if the field is a recursive field */
    relatedManager?: any;
    /** Custom GraphQL field details for subfields. */
    customGraphQl?: string;
    /** The fragment depth if it's a recursive field for the field itself. */
    recursiveFragmentDepth?: number;

    constructor(params: BusinessEntityFieldConstructorParams)
    {
        this.name = params.name;
        this.type = params.type;
        this.inputProperty = params.inputProperty ?? params.name;
        this.isInFieldDetails = params.isInFieldDetails ?? true;
        this.mapToProperty = params.mapToProperty ?? params.name;
        this.isInput = params.isInput ?? true;
        this.inputName = params.inputName;
        this.isRequiredInput = params.isRequiredInput ?? false;
        this.isArrayInput = params.isArrayInput ?? false;
        this.relatedManager = params.relatedManager;
        this.customGraphQl = params.customGraphQl;
        this.recursiveFragmentDepth = params.recursiveFragmentDepth ?? 1;
    }

    /**
     * Get the value for this field that is used for saving the entity via the GraphQL mutation.
     * 
     * @param entity The BusinessEntity object.
     */
    getInputValue(entity: BusinessEntity)
    {
        let inputValue: any = null;

        if (this.inputProperty.indexOf('.') == -1)
        {// Simple field. Eg: entity.name.
            if (entity[this.inputProperty] !== undefined) inputValue = entity[this.inputProperty];
        }
        else
        {// Composed field. Eg: entity.page.id or entity.pages.[id]
            let fieldSplits = this.inputProperty.split('.');
            let memberObject = entity;

            if (!this.isArrayInput)
            {
                // Simple composed field (eg: page.id)
                for (let fieldSplit of fieldSplits)
                {
                    memberObject = memberObject[fieldSplit];
                    if (memberObject === null || memberObject === undefined) break;
                }
            }
            else
            {
                // Array composed field (eg: an array of pages.id, ie: the ID of each page)
                for (let i = 0; i < fieldSplits.length; i++)
                {
                    if (i < fieldSplits.length - 1)
                    {
                        memberObject = memberObject[fieldSplits[i]];
                    }
                    else
                    {
                        memberObject = memberObject.map((m: any) => m[fieldSplits[i]]);
                    }
                    if (memberObject === null) break;
                }
            }

            if (memberObject !== undefined) inputValue = memberObject;
        }

        // Check if the value is required
        if (this.isRequiredInput && inputValue === null)
        {
            throw new Error(`Required variable <<${this.name}>> has no value set.`);
        }

        // Stringify the value if it's a JSON field
        if (this.type == BusinessEntityFieldType.Json)
        {
            inputValue = JSON.stringify(inputValue);
        }

        return inputValue;
    }
}

/**
 * Manages the data flow between the GraphQL API and the BusinessEntity object.
 */
export class EntityManager<B extends BusinessEntity>
{
    /** The name of the entity model. */
    name: string;
    /** A function that create an entity of type B */
    protected createEntity: () => B;
    /** The fields of the entity. These field settings connect BusinessEntity with GraphQL and Django. */
    fields: BusinessEntityField[] = [];
    /** The Connection query name for the entity in the GraphQL schema. */
    graphQlQueryAlias?: string;
    /** The name of the save mutation in the GraphQL schema */
    graphQlSaveAlias?: string;
    /** The name of the InputObjectType in the schema. */
    graphQlInputAlias?: string;
    /** The variables defined in the 'save' mutation. */
    graphQlDeleteAlias?: string;
    /** Fragments to add to the GraphQL entity query. */
    graphQlExtraFragments?: string[];
    /** Has the GraphQL Relay Connection query a total count? Mostly this is true. */
    hasTotalCount: boolean;
    /** Has the GraphQL Relay Connection query a pages array and a currentPage index? Mostly this is true. */
    hasPages?: boolean;

    constructor(parameters: EntityManagerParameters<B>)
    {
        this.name = parameters.name!;
        this.createEntity = parameters.createEntity!;
        this.fields = parameters.fields!.map(constructorInput => new BusinessEntityField(constructorInput));
        for (let field of this.fields)
        {
            if (field.relatedManager === undefined) continue;

            if (field.relatedManager == 'self')
            {
                field.relatedManager = this;
            }
        }
        this.graphQlQueryAlias = parameters.graphQlQueryAlias;
        this.graphQlSaveAlias = parameters.graphQlSaveAlias ?? 'save' + this.name;
        this.graphQlInputAlias = parameters.graphQlInputAlias ?? this.name + 'Input';
        this.graphQlDeleteAlias = parameters.graphQlDeleteAlias ?? 'delete' + this.name;
        this.graphQlExtraFragments = parameters.graphQlExtraFragments ?? [];
        this.hasTotalCount = parameters.hasTotalCount ?? true;
        this.hasPages = parameters.hasPages ?? true;
    }

    /**
     * Fill the entity's fields from a GraphQL object.
     * 
     * @param entity The entity.
     * @param graphQlObject An object from the GraphQL API response.
     */
    copyFromGraphQL(entity: B, graphQlObject: any)
    {
        // Set raw data
        entity.graphQlObject = graphQlObject;
        
        // Copy the ID
        if (graphQlObject.id !== undefined) 
        {
            entity.id = isNaN(graphQlObject.id) ? graphQlObject.id : parseInt(graphQlObject.id);
        }

        // Copy the fields
        for (let field of this.fields)
        {
            if (field.isInFieldDetails)
            {
                let graphQlFieldValue: any;

                if (field.relatedManager == undefined)
                {// Simple field, not an object
                    graphQlFieldValue = graphQlObject[field.name];

                    // Check string numbers (eg: decimals are sent by Graphene as strings, and they need to be transformed to numbers)
                    if (field.type == BusinessEntityFieldType.Integer && graphQlFieldValue != null)
                    {
                        graphQlFieldValue = parseInt(graphQlFieldValue);
                    }
                    else if (field.type == BusinessEntityFieldType.Decimal && graphQlFieldValue != null)
                    {
                        graphQlFieldValue = parseFloat(graphQlFieldValue);
                    }

                    // Parse the JSON value to an object
                    else if (field.type == BusinessEntityFieldType.Json && graphQlFieldValue != null)
                    {
                        graphQlFieldValue = JSON.parse(graphQlFieldValue);
                    }
                }
                else
                {// The field is a BusinessEntity or a list of BusinessEntities
                    if (!Array.isArray(graphQlObject[field.name]))
                    {
                        // A single entity
                        let relatedEntity = graphQlObject[field.name];
                        if (relatedEntity != null)
                        {
                            graphQlFieldValue = field.relatedManager.createFromGraphQL(relatedEntity);
                        }
                        else
                        {
                            graphQlFieldValue = null;
                        }
                    }
                    else
                    {
                        // A list of entities. Usually it is defined as a BusinessEntityFieldType.ReverseForeignKey or BusinessEntityFieldType.BusinessEntityArray
                        let relatedEntities: any[] = [];
                        let gqlEntities = graphQlObject[field.name];
                        for (let gqlEntity of gqlEntities)
                        {
                            let relatedEntity = field.relatedManager.createFromGraphQL(gqlEntity);
                            relatedEntities.push(relatedEntity);
                        }
                        graphQlFieldValue = relatedEntities;
                    }
                }

                // Assign
                //@ts-ignore
                entity[field.mapToProperty] = graphQlFieldValue;
            }
        }
    }

    /**
     * Create an entity from a GraphQL object.
     * 
     * @param graphQlObject An object from the GraphQL API response.
     */
    createFromGraphQL(graphQlObject: any)
    {
        let entity = this.createEntity();
        entity.copyFromGraphQL(graphQlObject);

        return entity;
    }

    /**
     * Load the entities from a GraphQL Connection query based on the filter. The result is paginated.
     * 
     * @param filter The filters as specified in the GraphQL schema.
     * @param paging The paging data for the results.
     * @returns A paginated collection of entities.
     */
    async load(filter?: BusinessEntityFilter, paging?: BusinessEntityPaging)
    {
        // Create paging string
        let pagingString = paging ? EntityManager.createQueryPagingString(paging) : '';

        // Create filter string
        let filterString = EntityManager.createQueryFilterString(filter, pagingString);

        // Create GraphQL query
        let query = `
            query {    
                ${this.graphQlQueryAlias} ${filterString} {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    ${this.hasTotalCount === true ? 'totalCount' : ''}
                    edges {
                        cursor
                        node {
                            ...${this.name}DetailsFragment
                        }
                    }
                    ${this.hasPages === true ? `
                    pages {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    currentPage
                    ` : ''}
                }
            }

            ${this.buildEntityDetailsFragment()}
        `;

        // Make the GraphQL query
        try
        {
            //console.log(query);
            let result = await client.query({
                query: gql(query)
            });

            if (result.errors === undefined)
            {
                return this.createPaginatedQueryResult(result);
            }
            else
            {
                console.log('Entity load query returned errors: ' + this.graphQlQueryAlias);
                console.log('Query: ' + query);
                console.log('Errors:', result.errors.map(e => e.message));

                return null;
            }
        }
        catch (e)
        {
            console.log('Entity load query failed: ' + this.graphQlQueryAlias);
            console.log('Query: ' + query);
            console.log('Error message: ' + (e as Error).message);

            return null;
        }
    }

    /**
     * Load entities with custom fields from a GraphQL Connection query based on the filter. The result is paginated.
     * 
     * @param customQuery The custom query for the fields.
     * @param filter The filters as specified in the GraphQL schema.
     * @param paging The paging data for the results.
     * @returns A paginated collection of entities.
     */
    async loadCustom(customQuery: string, filter?: BusinessEntityFilter, paging?: BusinessEntityPaging, fragments: string[] = [])
    {
        // Create paging string
        let pagingString = paging ? EntityManager.createQueryPagingString(paging) : '';

        // Create filter string
        let filterString = EntityManager.createQueryFilterString(filter, pagingString);

        // Create GraphQL query
        let query = `
            query {    
                ${this.graphQlQueryAlias} ${filterString} {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    ${this.hasTotalCount === true ? 'totalCount' : ''}
                    edges {
                        cursor
                        node {
                            ${customQuery}
                        }
                    }
                    ${this.hasPages === true ? `
                    pages {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    currentPage
                    ` : ''}
                }
            }

            ${fragments.map(f => f + '\n\n').join('')}
        `;

        // Make the GraphQL query
        try
        {
            let result = await client.query({
                query: gql(query)
            });

            if (result.errors === undefined)
            {
                return this.createPaginatedQueryResult(result);
            }
            else
            {
                console.log('Entity load custom query returned errors: ' + this.graphQlQueryAlias);
                console.log('Query: ' + query);
                console.log('Errors:', result.errors.map(e => e.message));

                return null;
            }
        }
        catch (e)
        {
            console.log('Entity load custom query failed: ' + this.graphQlQueryAlias);
            console.log('Query: ' + query);
            console.log('Error message: ' + (e as Error).message);

            return null;
        }
    }

    /**
     * Create the paging arguments string for GraphQL Connection query.
     * 
     * @param paging The paging info.
     */
    static createQueryPagingString(paging: BusinessEntityPaging)
    {
        let pagingString: string = '';
        if (paging?.first)
        {
            pagingString = `first: ${paging.first}`;
            if (paging.after) pagingString += `, after: "${paging.after}"`;
        }
        else if (paging?.last)
        {
            pagingString = `last: ${paging.last}`;
            if (paging.before) pagingString += `, before: "${paging.before}"`;
        }

        return pagingString;
    }

    /**
     * Create a filter string for a GraphQL Connection query.
     * 
     * @param filter The filter.
     * @param pagingString The paging string if exists.
     * @returns The filter string.
     */
    static createQueryFilterString(filter?: BusinessEntityFilter, pagingString: string = '')
    {
        let filterString = '';

        if (filter !== undefined)
        {
            let filterArray = [];

            for (const [key, value] of Object.entries(filter))
            {
                if (value === null || value === undefined || value === '') continue;

                let formattedValue = EntityManager.formatGraphQlParameterValue(value);
                if (formattedValue !== null && formattedValue !== '') 
                {
                    filterArray.push(key + ': ' + formattedValue);
                }
            }

            filterString = filterArray.join(', ');

            if (filterString != '') 
            {
                if (pagingString != '')
                {
                    // filter + paging
                    filterString = `(${pagingString}, ${filterString})`;
                }
                else
                {
                    // filter, no paging
                    filterString = `(${filterString})`;
                }
            }
            else
            {
                // paging, no filter
                if (pagingString != '') filterString = `(${pagingString})`;
            }
        }

        return filterString;
    }

    /**
     * Builds a GraphQL query fragment for the entity.
     * https://graphql.org/learn/queries/#fragments
     * 
     * Eg:
     * fragment MenuDetailsFragment on Menu {
        ...MenuFieldsFragment -> this fragment is built by buildEntityFieldsFragment()
        }
     * @param fragment A fragment to prepend to resulting fragment.
     * 
     * @returns The resulting fragment.
     */
    buildEntityDetailsFragment(fragment: string = '')
    {
        if (fragment != '') fragment += '\n\n';

        // The EntityDetails fragment wrapper
        fragment += `fragment ${this.name}DetailsFragment on ${this.name} {` + '\n';

        // Spread the field details fragment
        fragment += `...${this.name}FieldsFragment` + '\n';

        // Create recursive fragments spreads for fields that refer to the same entity
        for (let field of this.fields)
        {
            if (field.relatedManager && field.relatedManager.name == this.name)
            {
                fragment += this.buildFragmentSpreadForRecursiveField(field);
            }
        }

        // End EntityDetails fragment wrapper
        fragment += '}' + '\n';

        // Create the fields fragment
        fragment += '\n';
        fragment += this.buildEntityFieldsFragment(); + '\n';

        // Create EntityDetails fragments for related entities
        for (let field of this.fields)
        {
            if (field.relatedManager)
            {
                if (-1 == fragment.indexOf(`fragment ${field.relatedManager.name}DetailsFragment`))
                {
                    fragment = field.relatedManager.buildEntityDetailsFragment(fragment);
                }
            }
        }

        // Add extra fragments
        for (let extraFragment of this.graphQlExtraFragments!)
        {
            fragment += '\n\n';
            fragment += extraFragment;
        }

        return fragment;
    }

    /**
     * Build the GraphQL query part for the entity fields. 
     * Does not include fields that are referring to the same entity (GraphQL cannot spread a fragment within itself).
     * Eg:
     * 
        fragment MenuFieldsFragment on Menu {
        id
        name
        status
        location
        orderNo
        customUrl
        productCategory {
            ...ProductCategoryDetailsFragment
        }
        productCollection {
            ...ProductCollectionDetailsFragment
        }
        }

        @param skipFields An array of field names to skip.
     */
    buildEntityFieldsFragment(skipFields: string[] = [])
    {
        let fragment = '';
        fragment += `fragment ${this.name}FieldsFragment on ${this.name} {` + '\n';
        fragment += 'id' + '\n';

        for (let field of this.fields)
        {
            if (skipFields.includes(field.name)) continue;
            
            if (field.isInFieldDetails)
            {
                if (field.relatedManager == null)
                {
                    if (field.customGraphQl === undefined)
                    {
                        // simple field
                        fragment += field.name + '\n';
                    }
                    else
                    {
                        // simple field with custom GraphQL
                        fragment += `${field.name} {` + '\n';
                        fragment += field.customGraphQl + '\n';
                        fragment += `}` + '\n';
                    }
                }
                else if (field.relatedManager.name != this.name)
                {
                    // field for a related entity (spread the EntityDetailsFragment for the related entity)
                    fragment += `${field.name} {` + '\n';
                    fragment += `...${field.relatedManager.name}DetailsFragment` + '\n';
                    fragment += '}' + '\n';
                }
            }
        }

        fragment += '}';

        return fragment;
    }

    /**
     * Build a recursive fields fragment spread.
     * 
     * Eg: 
     * parent {
        ...MenuFieldsFragment
        parent {
            ...MenuFieldsFragment
            parent {
                ...MenuFieldsFragment
                parent {
                    ...MenuFieldsFragment
                }
            }   
        }
    }
     * 
     * @param field 
     * @param depth 
     */
    buildFragmentSpreadForRecursiveField(field: BusinessEntityField, depth?: number)
    {
        if (depth === undefined) depth = field.recursiveFragmentDepth as number;
        if (depth == 0) return '';

        let fragment = '';

        fragment += `${field.name} {` + '\n';
        fragment += `...${this.name}FieldsFragment` + '\n';
        fragment += this.buildFragmentSpreadForRecursiveField(field, depth - 1)
        fragment += '}' + '\n';

        return fragment;
    }

    /**
     * Format a value so it can be passed to a GraphQL query.
     * Number: 100, 12.5, 12323.23
     * String: "The string"
     * Boolean: true, false
     * Array of numbers: [1, 32, 4, 56]
     * Array of strings: ["A", "B", "C"]
     * Array of booleans: [true, false, true]
     * 
     * @param value 
     * @returns The formatted value.
     */
    static formatGraphQlParameterValue(value: any)
    {
        if (value === null) return null;
        if (Array.isArray(value) && value.length == 0) return null;

        let formattedValue = value;

        if (typeof value == 'string')
        {
            formattedValue = `"${value}"`;
        }
        else if (typeof value == 'boolean')
        {
            formattedValue = value ? 'true' : 'false';
        }
        else if (Array.isArray(value))
        {
            let arrayOfValues = value;
            if (typeof value[0] == 'string')
            {
                arrayOfValues = value.map(v => `"${v}"`)
            }
            else if (typeof value[0] == 'boolean')
            {
                arrayOfValues = value.map(v => v ? 'true' : 'false')
            }
            formattedValue = '[' + arrayOfValues.join(", ") + ']';
        }

        return formattedValue;
    }

    /**
     * Create a paginated result object from a GraphQL query result.
     * 
     * @param result 
     * @returns The a paginated result object.
     */
    createPaginatedQueryResult(result: ApolloQueryResult<any>)
    {
        // Create entities
        let entities: B[] = [];
        if (result.data[this.graphQlQueryAlias!].edges != null)
        {
            for (let edge of result.data[this.graphQlQueryAlias!].edges)
            {
                let entity = this.createEntity();
                entity.copyFromGraphQL(edge.node);
                entity.cursor = edge.cursor;
                entities.push(entity);
            }
        }

        // The paginated result
        let paginatedResult: PaginatedResult<B> = {
            entities: entities,
            paginationInfo:
            {
                totalCount: result.data[this.graphQlQueryAlias!].totalCount !== undefined ? result.data[this.graphQlQueryAlias!].totalCount as number : -1,
                hasPreviousPage: result.data[this.graphQlQueryAlias!].pageInfo.hasPreviousPage as boolean,
                hasNextPage: result.data[this.graphQlQueryAlias!].pageInfo.hasNextPage as boolean,
                startCursor: result.data[this.graphQlQueryAlias!].pageInfo.startCursor as string,
                endCursor: result.data[this.graphQlQueryAlias!].pageInfo.endCursor as string,
                pages: result.data[this.graphQlQueryAlias!].pages !== undefined ? result.data[this.graphQlQueryAlias!].pages.map((p: any) => ({
                    hasPreviousPage: p.hasPreviousPage as boolean,
                    hasNextPage: p.hasNextPage as boolean,
                    startCursor: p.startCursor as string,
                    endCursor: p.endCursor as string,
                })) : [],
                currentPage: result.data[this.graphQlQueryAlias!].currentPage !== undefined ? result.data[this.graphQlQueryAlias!].currentPage : null,
            },
        };

        return paginatedResult;
    }

    /**
     * Gets a single entity based on a filter.
     * 
     * @param filter The filter as specified in the GraphQL schema.
     * @returns The entity or null if nothing or more than one entities are found.
     */
    async get(filter?: BusinessEntityFilter)
    {
        let result = await this.load(filter);

        if (result != null && result.entities.length == 1)
        {
            return result.entities[0];
        }
        else
        {
            return null;
        }
    }

    /**
     * Gets a single entity by id.
     */
    async getById(id: number|string)
    {
        let filter: BusinessEntityFilter = {
            id: id
        };

        return await this.get(filter);
    }

    /**
     * Gets a single entity with custom fields based on a filter.
     * 
     * @param customQuery The custom GraphQL query.
     * @param filter The filter as specified in the GraphQL schema.
     * @returns The entity or null if nothing or more than one entities are found.
     */
    async getCustom(customQuery: string, filter?: BusinessEntityFilter)
    {
        let result = await this.loadCustom(customQuery, filter);

        if (result != null && result.entities.length == 1)
        {
            return result.entities[0];
        }
        else
        {
            return null;
        }
    }

    /**
     * Save the entity.
     * 
     * @param entity 
     * @returns True if the save was succesful.
     */
    async saveEntity(entity: B)
    {
        let gqlSaveMutation = this.createGraphQlSaveMutation();
        let variables = {
            input: this.createInputObject(entity)
        }

        // Execute mutation
        let success = false;
        try
        {
            let result = await client.mutate({
                mutation: gql(gqlSaveMutation),
                variables: variables
            });

            success = result.data[this.graphQlSaveAlias!].success as boolean;

            if (success)
            {
                // The entity is reloaded from the GraphQL result
                let graphQlObject = result.data[this.graphQlSaveAlias!].object;
                entity.copyFromGraphQL(graphQlObject);
            }
            else
            {
                console.log('saveEntity mutation not successful: ' + this.graphQlSaveAlias);
                console.log('Mutation: ', gqlSaveMutation);
                console.log('Variables: ', JSON.stringify(variables));
            }
        }
        catch (e)
        {
            console.log('Error: save entity mutation failed: ' + this.graphQlSaveAlias);
            console.log('Error message: ' + (e as Error).message);
            console.log('Mutation: ', gqlSaveMutation);
            console.log('Variables: ', JSON.stringify(variables));
            throw e;
        }

        return success;
    }

    /**
     * Create the GraphQL save mutation for the BusinessEntity.
     * https://graphql.org/learn/queries/#mutations
     * 
     * @param entity
     */
    createGraphQlSaveMutation()
    {
        let saveMutation = '';
        saveMutation += `mutation Save${this.name}($input: ${this.graphQlInputAlias}!) {` + '\n';
        saveMutation += `${this.graphQlSaveAlias}(input: $input) {` + '\n';
        saveMutation += 'success' + '\n';
        saveMutation += 'object {' + '\n';
        saveMutation += `...${this.name}DetailsFragment` + '\n';
        saveMutation += '}' + '\n';
        saveMutation += '}' + '\n';
        saveMutation += '}' + '\n';
        saveMutation += this.buildEntityDetailsFragment();

        return saveMutation;
    }

    /**
     * Create an input object for the GraphQL save mutation.
     * https://graphql.org/learn/schema/#input-types
     * 
     * @param entity 
     */
    createInputObject(entity: B)
    {
        let inputObject: { [index: string]: any } = {};

        inputObject.id = entity.id;

        for (let field of this.fields)
        {
            if (field.isInput)
            {
                // Check required field
                if (entity[field.name] === undefined && field.isRequiredInput)
                {
                    throw new Error(`Required input field <<${field.name}>> on <<${this.name}>> has no value set.`);
                }

                // The input field name (the input field name can differ from the actual field name. Eg: region -> regionId)
                let inputFieldName = field.inputName ?? field.name;

                inputObject[inputFieldName] = field.getInputValue(entity);
            }
        }

        return inputObject;
    }

    /**
     * Deletes the entity via GraphQL.
     * 
     * @param entity The entity to delete.
     * @returns True if succeeded, false if not.
     */
    async deleteEntity(entity: B)
    {
        // Create input
        let idString = '';
        if (entity.id != null)
        {
            idString = (typeof entity.id == 'string') ? `id: "${entity.id}"` : `id: ${entity.id}`;
        }
        else
        {
            return false; // Cannot delete without an ID
        }

        // Create GraphQL mutation
        let mutation = `
        mutation {
            ${this.graphQlDeleteAlias} (${idString}) {
                success
            }
        }
        `;

        // Execute mutation
        let success = false;
        try
        {
            let result = await client.mutate({
                mutation: gql(mutation)
            });

            return result.data[this.graphQlDeleteAlias!].success as boolean;
        }
        catch (e)
        {
            console.log('Error: deleteEntity mutation failed: ' + this.graphQlDeleteAlias);
            console.log('Mutation: ', mutation);
            console.log('Error message: ' + (e as Error).message);

            throw e;
        }
    }
}

/**
 * Represents the paginated result of a query for entities.
 */
export type PaginatedResult<B extends BusinessEntity> = {
    /** The entities found on this page. */
    entities: B[],
    /** The pagination info */
    paginationInfo: PaginationInfo
}

export type PaginationInfo = {
    /** The total number of available entities. Not just the returned entities. */
    totalCount: number;
    /** Is there a previuos page after the current page in the results? */
    hasPreviousPage: boolean,
    /** Is there a next page after the current page in the results? */
    hasNextPage: boolean,
    /** The cursor of the first item on the current page. */
    startCursor: string,
    /** The cursor of the last item on the current page. */
    endCursor: string,
    /** The available pages and their pagination info. */
    pages?: PageInfo[],
    /** The current page's index (0 based) */
    currentPage?: number
}

export type PageInfo = {
    /** Is there a previuos page in the results? */
    hasPreviousPage: boolean,
    /** Is there a next page in the results? */
    hasNextPage: boolean,
    /** The cursor of the first item on this page. */
    startCursor: string,
    /** The cursor of the last item on this page. */
    endCursor: string,
}

/**
 * Represents a filter that can be passed to a GraphQL query.
 */
export type BusinessEntityFilter = {
    [index: string]: any
}

/**
 * Represents the query paging limits for load().
 */
export type BusinessEntityPaging = {
    first?: number,
    after?: string,
    last?: number,
    before?: string
}

export type BusinessEntityFieldConstructorParams = {
    /** The field name. */
    name: string,
    /** The field type. */
    type: BusinessEntityFieldType,
    /** The class property associated with the input field. By default this is the field name. Can refer to a property of a property, eg: page.author.id. */
    inputProperty?: string,
    /** Is the field present in the GraphQL field details query (FieldsFragment). */
    isInFieldDetails?: boolean,
    /** The property of the class that the field is mapped to. By default this is the field name. */
    mapToProperty?: string,
    /** Is the field a GraphQL input field. */
    isInput?: boolean,
    /** Use this if the GraphQL input field name is different from 'name'. Eg: name: user -> inputName: userId, name: imageUrl -> inputName: image */
    inputName?: string,
    /** Is this field a required input? */
    isRequiredInput?: boolean,
    /** Is the input an array of entities? */
    isArrayInput?: boolean;
    /** The EntityManager object for this field (the field represents an entity). */
    relatedManager?: any // EntityManager
    /** Custom GraphQL field details for subfields. */
    customGraphQl?: string;
    /** The fragment depth if it's a recursive field for the field itself. */
    recursiveFragmentDepth?: number;
}

export interface EntityManagerParameters<B extends BusinessEntity> 
{
    /** The name of the entity model. */
    name?: string;
    /** A function that create an entity of type B */
    createEntity?: () => B;
    /** The BusinessEntityField settings.  */
    fields?: BusinessEntityFieldConstructorParams[],
    /** The Connection query name for the entity in the GraphQL schema. */
    graphQlQueryAlias?: string;
    /** The name of the save entity mutation in the schema. */
    graphQlSaveAlias?: string;
    /** The name of the InputObjectType in the schema. */
    graphQlInputAlias?: string;
    /** The input variables defined in the arguments of 'save' mutation. */
    graphQlDeleteAlias?: string;
    /** Fragments to add to the GraphQL entity query. */
    graphQlExtraFragments?: string[];
    /** Has the GraphQL Relay Connection query a total count? Mostly this is true. */
    hasTotalCount?: boolean;
    /** Has the GraphQL Relay Connection query a pages array? Mostly this is true. */
    hasPages?: boolean;
}

export default BusinessEntity;